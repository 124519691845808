import React from "react"
import { navigate } from "gatsby-link"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../components/layout"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Form = styled.form`
  width: 80%;
  background: #ededed;
  border-radius: 5px;
  padding: 5px;
  margin: auto;

  .field {
    margin: 5px;
    padding: 10px;
    background: #ffffff;
  }

  .sendButton {
    text-align: center;

    .button {
      font-size: 20px;
    }
  }

  label {
    font-size: 16px;
  }

  input,
  select {
    border: 1px solid #444;
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    margin: 10px 20px 10px 0px;
  }

  textarea {
    border: 1px solid #444;
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    margin: 10px 20px 10px 0px;
  }
`

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    const locations = this.props.data.allVenue.nodes
    return (
      <Layout>
        <SEO title="Contact Us" keywords={[`contact`]} />

        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Contact Us</h1>
              <Form
                name="Contact Us - MamasPizzas.net"
                method="post"
                action="/contactsuccess"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"name"}>
                    Your name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"name"}
                      onChange={this.handleChange}
                      id={"name"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"email"}>
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"email"}
                      name={"email"}
                      onChange={this.handleChange}
                      id={"email"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"phone"}>
                    Phone Number
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"phone"}
                      name={"phone"}
                      onChange={this.handleChange}
                      id={"phone"}
                      required={false}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"location"}>
                    Location
                  </label>
                  <div className="control">
                    <select
                      className="select"
                      name={"location"}
                      onBlur={this.handleChange}
                      id={"location"}
                    >
                      <option>General</option>
                      {locations.map(l => (
                        <option>{l.venueNameShort}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"message"}>
                    Message
                  </label>
                  <div className="control">
                    <textarea
                      rows={6}
                      className="textarea"
                      name={"message"}
                      onChange={this.handleChange}
                      id={"message"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="sendButton">
                  <button className="button is-link" type="submit">
                    Send
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allVenue {
      nodes {
        venueNameShort
      }
    }
  }
`
